/**
 * ESTILOS GLOBALES DE LA APLICACION
 * CSS desarrollado en trabajo de diseño
 * Tratar de no modificar!
 * Se agregaron algunas clases
 */

@import url('https://use.typekit.net/bvn2rnh.css');
@import url('https://db.onlinewebfonts.com/c/3752a0f4c3f80b95df1301564a50b30e?family=NexaBlack');

@font-face {
  font-family: "NexaBlack";
  src: url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.eot");
  src: url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/3752a0f4c3f80b95df1301564a50b30e.svg#NexaBlack")format("svg");
}

:root {
  /* --primary: #ba2229; */
  /* --primary-400: #b9262d; */
  /* --primary-600: #d3323a; */
  /* --primary-700: #e4434b; */
  /* --primary-800: #fa5e66; */
  /* --primary-900: #ffa0a5; */
  --primary: #3A2566;
  --primary-400: #4d3a81;
  --primary-600: #634f9b;
  --primary-700: #765fad;
  --primary-800: #8b75c2;
  --primary-900: #ad99db;
  --primary-contrast: rgb(241,156,73);
  --black: #000;
  --white: #fff;
  --text-black: #181818;
  --text-muted: #7e7e7e;
  --footer-bg: #10140e;
  --gray-1: #f5f5f5;
  --gray-2: #ebebeb;
  --gray-3: #c2c1c2;
  --gray-4: #707070;
  --gray-5: #2b2b2b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: halcom, sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizelegibility;
}

body {
  /* font-family: halcom, sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  /* font-size: 	1.2em; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: halcom, sans-serif;
  font-weight: 700; */
  font-family: 'NexaBlack', sans-serif;
  font-style: normal;
}

h1 {
  font-size: 2.4em;
  line-height: 100%;
}
h2 {
  font-size: 2.1em;
  line-height: 100%;
}
h3 {
  font-size: 1.8em;
  line-height: 100%;
}
h4 {
  font-size: 1.4em;
  line-height: 100%;
}
h5 {
  font-size: 1.2em;
  line-height: 100%;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2.8em;
    line-height: 100%;
  }
  h2 {
    font-size: 2.3em;
    line-height: 100%;
  }
  h3 {
    font-size: 2em;
    line-height: 100%;
  }
  h4 {
    font-size: 1.6em;
    line-height: 100%;
  }
  h5 {
    font-size: 1.4em;
    line-height: 100%;
  }
}

a {
  color: var(--primary-contrast); /* var(--primary); */
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.2em;
}

@media (min-width: 768px) {
  .container {
    width: 990px;
    margin: 0 auto;
    padding: 0 1em;
  }
}

@media (min-width: 990px) {
  .container {
    width: 1020px;
    margin: 0 auto;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1190px;
    margin: 0 auto;
    padding: 0;
  }
}

:focus-visible {
  outline-color: var(--primary-contrast); /* #ba222a8a; */
}

header {
  position: sticky;
  top: 0;
  max-height: 65px;
  background: var(--white);
  color: var(--text-black);
  box-shadow: 0px 1px var(--gray-3);
  z-index: 9;
}

header a {
  color: var(--text-black);
  text-decoration: none;
}

header a:hover {
  color: var(--primary);
}

.header-section {
  padding: 5em 0;
}

.nav-container {
  display: flex;
  max-height: 65px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.main-nav {
  display: none;
  position: absolute;
  padding-top: 1.3em;
  width: 100%;
  top: 65px;
  left: 0;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  background-color: var(--white);
  height: 100vh;
}

.main-nav.show {
  display: block;
}

@media (min-width: 768px) {
  .main-nav {
    display: flex;
    position: relative;
    width: fit-content;
    padding-top: 0;
    top: 0;
    height: auto;
  }
}

.main-nav > li {
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.2em;
  position: relative;
}
@media (min-width: 768px) {
  .main-nav > li {
    flex-direction: row;
    align-items: center;
    padding: 0 1.2em;
  }
}

.main-nav li .fa-angle-down {
  margin-left: 5px;
}

#mobile-toggle {
  display: block;
  padding: 1.5rem;
  font-size: 1.4em;
}

@media (min-width: 768px) {
  #mobile-toggle {
    display: none;
  }
}

.dropdown-title {
  font-size: 18px;
  cursor: pointer;
}
.dropdown-title:hover {
  color: var(--primary);
}
@media (min-width: 768px) {
  .dropdown-title {
    font-size: 16px;
  }
}

.nav-dropdown {
  position: absolute;
  will-change: transform;
  width: 100%;
  top: 40px;
  padding: 1em;
  left: 0;
  gap: 30px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-3);
  display: grid;
  visibility: hidden;
  grid-template-columns: 1fr;
  transform: translate3d(0px, -10%, 0px);
  transition: all 0.15s ease-in-out 0.1s;
  z-index: -1;
}
@media (min-width: 768px) {
  .nav-dropdown {
    position: fixed;
    top: 65px;
    padding: 2em 10em;
    left: 0;
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.dropdown-toggle :hover + .main-nav li .fa-angle-down {
  transform: rotate(180deg);
}

.dropdown-toggle :hover + .nav-dropdown,
.dropdown-toggle :focus-within + .nav-dropdown,
.dropdown-toggle :focus + .nav-dropdown,
.nav-dropdown:hover,
.nav-dropdown:focus,
.nav-dropdown:focus-within {
  visibility: visible;
  transform: translateY(0%);
  z-index: 2;
}

.dropdown-toggle:hover div.dropdown-title {
  color: var(--primary);
}

@media (min-width: 768px) {
  .dropdown-toggle :hover + .nav-dropdown,
  .dropdown-toggle :focus-within + .nav-dropdown,
  .dropdown-toggle :focus + .nav-dropdown,
  .nav-dropdown:hover,
  .nav-dropdown:focus,
  .nav-dropdown:focus-within {
    visibility: visible;
    transform: translateY(-1%);
    z-index: -1;
  }
}

.online {
  display: none;
  gap: 0.6em;
}
@media (min-width: 768px) {
  .online {
    display: flex;
    align-items: center;
  }
}

.current-clients {
  display: flex;
  flex-direction: column;
}

#new-client {
  display: none;
}

#new-client.active {
  display: block;
}

.logo {
  height: 70px;
  padding: 5px;
  margin-right: 15px;
}

.modal-logo {
  height: 80px;
  padding: 5px;
  margin-bottom: 2.5em;
}

.content-sect {
  margin-top: 3em;
  margin-bottom: 5em;
}

/* Hero section */
.hero {
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%);
    /* url(./assets/img/hero-bg.png) center; ahora es dinamico */
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
  height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.hero--tarjetas {
  background: #f5f5f5 !important;
  color: inherit !important;
}
.hero--prestamos {
  background: url('https://dummyimage.com/1920x900/858585/696969') center !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.foto-destacada {
  margin: 5em 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.foto-destacada img {
  width: 102%;
}

.hola {
  color: #696969;
}
@media (min-width: 768px) {
  .hero {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%);
      /* url(./assets/img/hero-bg.png) center; ahora es dinamico */
    height: 88vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.hero-text {
  width: 90%;
  margin-left: 1rem;
  margin-top: 4em;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .hero-text {
    width: 28%;
    margin-left: 8em;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-text h1 {
  margin-bottom: 10px;
}

.hero-form {
  background: var(--white);
  padding: 34px;
  display: block;
  color: var(--text-black);
  margin-right: auto;
  margin-left: auto;
  width: 85%;
}

.hero-image {
  margin-right: auto;
  margin-left: auto;
  width: 60%;
}
@media (min-width: 768px) {
  .hero-form {
    margin-left: auto;
    margin-right: 4em;
    width: 30%;
  }

  .hero-image {
    margin-left: auto;
    margin-right: 2em;
    width: 40%;
  }
}

.hero-form h3 {
  margin-bottom: 50px;
}

.hero-form input {
  border: 1px solid var(--gray-3);
  padding: 18px 18px 15px;
  width: 100%;
  margin-bottom: 20px;
}

.hero-form input::placeholder {
  color: var(--text-black);
}

.btn-hero-form {
  background: var(--primary);
  border: none;
  padding: 1.2rem;
  width: 100%;
  border-radius: 90px;
  color: var(--white);
}

.inner-hero {
  height: 65vh;
  background: url(./assets/img/about-us-hero.png) no-repeat center;
  background-size: cover;
}

.inner-title {
  padding: 7em 2em;
  text-align: center;
}

@media (min-width: 768px) {
  .inner-title {
    padding: 7em;
  }
}

#sect-prestamos {
  padding: 90px 0;
}

.prestamos {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.4em;
}

@media (min-width: 768px) {
  .prestamos {
    flex-direction: row;
    padding: 0;
  }
}

.tipo-prestamo {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding: 5em 2em;
  color: var(--white);
  margin-bottom: 3em;
}

.tipo-prestamo:last-of-type {
  margin-bottom: 0;
}

.tipo-prestamo__content {
  width: 100%;
}

@media (min-width: 768px) {
  .tipo-prestamo {
    padding: 5em 4em;
  }

  .tipo-prestamo__content {
    width: 50%;
  }
}

.actions {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .actions {
    flex-direction: row;
  }
}

#sect-tarjetas {
  background: var(--gray-1);
  padding: 90px 0;
}

.tarjetas {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.4em;
}

.prestamos-text,
.tarjetas-text {
  width: 90%;
}

@media (min-width: 768px) {
  .tarjetas {
    flex-direction: row;
    padding: 0;
  }
  .prestamos-text,
  .tarjetas-text {
    width: 45%;
  }
}

.section-photo-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 3em 1em;
}

.section-w-text {
  width: 90%;
}
.section-w-photo {
  width: 90%;
  margin-bottom: 3em;
}
.section-w-photo img {
  max-width: 100%;
}

.section-w-text__item {
  margin-bottom: 3em;
}

.section-w-text__item:last-of-type {
  margin-bottom: 0;
}

.section-w-text__item > i {
  font-size: 2em;
  margin-bottom: 0.4em;
}

@media (min-width: 768px) {
  .section-photo-text {
    flex-direction: row;
    padding: 6em 0;
  }
  .section-w-photo {
    width: 45%;
    margin-bottom: 0;
  }
  .section-w-text {
    width: 45%;
  }
}

.frase-destacada {
  text-align: center;
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .frase-destacada {
    padding: 7rem 0;
  }
}

#cuentas {
  background: linear-gradient(130deg, rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0));
    /* url(./assets/img/cuentas-bg.png) center; ahora es dinamico */
  height: 85vh;
  background-size: auto 110%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: var(--white);
  transition: all 0.35s ease;
  padding: 0 1.4em;
}
#seguros {
  background: linear-gradient(130deg, rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0)),
    url(./assets/img/seguros-bg.png) center;
  height: 85vh;
  background-size: auto 110%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: var(--white);
  transition: all 0.35s ease;
  padding: 0 1.4em;
}
@media (min-width: 768px) {
  #cuentas {
    background-size: auto 110%;
    padding: 0;
  }
  #seguros {
    background-size: auto 110%;
    padding: 0;
  }
}
#cuentas:hover,
#seguros:hover {
  background-size: auto 115%;
}
.seguros-info,
.cuentas-info {
  width: 90%;
}
@media (min-width: 768px) {
  #cuentas:hover,
  #seguros:hover {
    background-size: auto 115%;
  }
  .seguros-info,
  .cuentas-info {
    width: 40%;
  }
}
.cuentas-info .btn-ghost,
.seguros-info .btn-ghost {
  text-decoration: none;
  border: 1px solid transparent;
  padding: 0.6em 0.9em;
  border-radius: 90px;
  color: var(--white);
  margin-left: -10px;
  transition: all 0.25s ease;
}
#cuentas:hover .btn-ghost,
#seguros:hover .btn-ghost {
  border: 1px solid var(--primary);
  background-color: var(--primary);
}

/* Testimonios */
.testimonios-sect {
  padding: 5em 0;
  background-color: var(--gray-1);
}

.testimonios-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
}

@media (min-width: 768px) {
  .testimonios-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.testimonio-item {
  text-align: center;
  padding: 15px;
}

.testimonio-item__photo {
  border-radius: 170px;
  margin-bottom: 1.5em;
}

.testimonio-item blockquote {
  margin-top: 1.5em;
  position: relative;
}

.testimonio-item blockquote::before {
  content: '“';
  opacity: 0.1;
  font-family: serif;
  font-size: 7em;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonio-item blockquote::after {
  content: '”';
  opacity: 0.1;
  font-family: serif;
  font-size: 7em;
  position: absolute;
  top: 0;
  right: 0;
}

footer {
  background-color: var(--footer-bg);
  color: var(--white);
  padding: 110px 20px 48px;
}

@media (min-width: 768px) {
  footer {
    padding: 110px 0 48px;
  }
}

footer h5 {
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: 400;
  margin-bottom: 20px;
}

footer a {
  color: var(--white);
  text-decoration: none;
}

footer a:hover {
  color: var(--primary-contrast); /* var(--primary); */
}

footer .fa-external-link {
  margin-left: 0.5em;
}

.footer-links {
  display: grid;
  grid-template-columns: 1fr;
  gap: 45px;
}

@media (min-width: 768px) {
  .footer-links {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
}

.footer-contact {
  display: grid;
  grid-template-columns: 1fr;
  gap: 45px;
  margin-top: 4.5em;
}

@media (min-width: 768px) {
  .footer-contact {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

.footer-copyright {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid var(--gray-4);
}

.footer-copyright p {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .footer-copyright {
    flex-direction: row;
    align-items: center;
  }

  .footer-copyright p {
    margin-top: 0;
  }
}

.footer-copyright > img {
  margin-right: 20px;
}

.social-nav {
  font-size: 1.8rem;
}
.social-nav a {
  margin-right: 8px;
}
.store-link {
  width: 35%;
}
@media (min-width: 768px) {
  .store-link {
    width: 60%;
  }
}

#news-warn {
  color: var(--gray-3);
}

.newsletter input {
  border: 1px solid var(--gray-2);
  background-color: transparent;
  padding: 18px 18px 15px;
  width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  color: var(--white);
  font-size: 16px;
}

.newsletter input::placeholder {
  color: var(--white);
}

.newsletter button {
  background: var(--primary);
  border: none;
  padding: 1.2rem 4rem;
  border-radius: 90px;
  color: var(--white);
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  min-width: 300px;
}

@media (min-width: 768px) {
  .newsletter button {
    /* margin-left: 1.5em; */
  }
}
.our-numbers {
  background: var(--gray-1);
  padding: 6.5em 0;
}
.our-numbers h2 {
  margin-bottom: 2em;
}
.numbers-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  width: 50%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .numbers-items {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 75%;
  }
}
.our-values {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  padding: 0 2.4em;
}
@media (min-width: 768px) {
  .our-values {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0;
  }

  .our-values div {
    max-width: 250px;
  }
}
.our-values__title {
  font-weight: 400;
  margin-bottom: 1em;
}

.history-text {
  width: 60%;
  margin: 1em auto 5em auto;
}
.history-text p {
  margin-bottom: 25px;
}
.about-photo {
  width: 100%;
  margin-bottom: -4px;
}
.misionvision {
  padding: 6.5em 0;
}
.misionvision-item {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 4.3em;
}
@media (min-width: 768px) {
  .misionvision-item {
    width: 40%;
  }
}
.misionvision-item:last-child {
  margin-bottom: 0;
}
.misionvision-item__title {
  margin-bottom: 1rem;
}
.values-sect {
  padding: 6.5em 0;
}
.values-sect__title {
  text-align: center;
  margin-bottom: 2em;
}
.plana-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
}
.plana-list h5 {
  font-weight: 300;
  margin-bottom: 5px;
}
.plana-list-item {
  padding: 0.8em 0;
}
.our-nav-sect {
  padding: 5em 0 3em;
}
.tabs-group {
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 18px;
}
@media (min-width: 768px) {
  .tabs-group {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.tabs {
  border-bottom: 2px solid var(--gray-3);
  padding: 1.5em;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.tabs.tabs--active {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}
/* Cotizaciones */
.cotizaciones-table {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
  margin: 0 auto 5em;
}
@media (min-width: 768px) {
  .cotizaciones-table {
    grid-template-columns: 1fr 1fr;
  }
}
.cotizacion {
  padding: 1.2em;
  border: 1px solid var(--gray-3);
}
.cotizacion__header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1.5em;
}
.cot-money {
  display: flex;
  gap: 1.2em;
  justify-content: space-between;
}
.cot-money__concept {
  width: 50%;
}
.cot-money__ammount {
  font-size: 18px;
}
.cotizacion__flag {
  width: 65px;
  height: 65px;
  background: var(--gray-2);
  border-radius: 65px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.flag__us {
  background: url(./assets/img/flags/us-flag.png);
}
.flag__ue {
  background: url(./assets/img/flags/ue-flag.png);
}
.flag__br {
  background: url(./assets/img/flags/br-flag.png);
}
.flag__ar {
  background: url(./assets/img/flags/ar-flag.png);
}
.flag__uy {
  background: url(./assets/img/flags/uy-flag.png);
}
/* Calculadora */
.calculadora {
  display: block;
  height: auto;
}
@media (min-width: 768px) {
  .calculadora {
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: calc(100vh - 65px);
  }
}
.calculadora__info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 4em 1.5em;
  width: 100%;
}
@media (min-width: 768px) {
  .calculadora__info {
    padding: 3em;
    width: 80%;
  }
}
.calculadora__result {
  padding: 4em 1.5em;
  background: var(--primary);
  color: white;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}
.calculadora__result.show {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 768px) {
  .calculadora__result {
    padding: 3em;
  }
}
.tipo-prestamo-select {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (min-width: 768px) {
  .tipo-prestamo-select {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/* Ventajas */
.ventajas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5em 1.5em;
  padding: 5em 0;
}
@media (min-width: 768px) {
  .ventajas {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* Pagina tarjetas */
.tipos-tarjetas {
  display:flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-top: 5em;
  margin-bottom: 8em;
  justify-content: center;
}

.tipo-tarjeta {
  text-align: center;
  max-width: 250px;
}

.tipo-tarjeta img {
  border-radius: 7px;
  margin-bottom: 1.5em;
}

.tipo-tarjeta h2 {
  margin-bottom: 1em;
}

.tipo-tarjeta p {
  margin-bottom: 1em;
}

@media (min-width: 528px) {
  .tipos-tarjetas {
    display:flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .tipos-tarjetas {
    display:flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
  }
}

/* Faqs component */
.faq {
  padding: 6em 0;
}
.faq h2 {
  text-align: center;
  margin-bottom: 1.5em;
}
.faq-item {
  border-top: 1px solid var(--gray-4);
  border-bottom: 1px solid var(--gray-4);
  cursor: pointer;
  margin-top: -1px;
}
.faq-item-title {
  display: flex;
  justify-content: space-between;
  padding: 1em 0.7em;
}
.faq-answer {
  visibility: hidden;
  transform: rotateX(90deg);
  transform-origin: top;
  max-height: 0;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}
.faq-answer.active {
  max-height: 250px;
  transform: rotateX(0deg);
  visibility: visible;
  opacity: 1;
  padding: 0 1em 1em;
}

.fa-rotate-45 {
  transform: rotate(45deg);
}

/* Modal */
.modal-action {
  padding: 1.5rem;
  position: sticky;
  top: 0;
  background-color: var(--white);
}

.modal {
  width: 90%;
  padding: 1rem;
  margin: 0 auto 5em;
}

.modal-header {
  margin-bottom: 4em;
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .modal {
    width: 60%;
  }
}

/* Steps indicator onboarding*/
.steps-indicator {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* gap: 20px; */
  margin: 3em 0;
}
.step {
  padding: 1.2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--gray-3);
  text-decoration: none;
  color: inherit;
}
.step__icon {
  margin-bottom: 0.6em;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-radius: 100px;
  background-color: var(--gray-2);
}
.step > p {
  font-size: 18px;
  display: none;
}
@media (min-width: 768px) {
  .step > p {
    display: block;
  }
}
.step.completed {
  border-bottom: 2px solid var(--primary);
}
.step.active {
  border-bottom: 2px solid var(--primary);
}
.step.completed > .step__icon {
  color: var(--primary);
}
.step.active > .step__icon {
  color: var(--white);
  background-color: var(--primary);
}
/* Memoria y balance */
.memorias {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .memorias {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.memoria-item {
  padding: 1.5em;
  border: 1px solid var(--gray-3);
  border-radius: 10px;
  text-decoration: none;
  color: inherit;
  background-color: var(--white);
  transition: all 0.45s ease-in-out;
}

.memoria-item:hover {
  background-color: var(--gray-1);
}

.memoria-item i {
  margin-bottom: 2em;
  font-size: 20px;
}

/* helpers */
.bg-white {
  background-color: var(--white);
}
.bg-primary {
  background-color: var(--primary);
}
.bg-gray {
  background-color: var(--gray-1);
}
.text-primary {
  color: var(--primary);
}
.text-muted {
  color: var(--text-muted) !important;
}
.text-danger {
  color: #ba2229 !important;
}

.is-invalid {
  border-color: #ba2229 !important;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block;
}

.content-between {
  justify-content: space-between;
}

.content-center {
  justify-content: center;
}

.content-right {
  justify-content: right;
}

.items-between {
  align-items: flex-between;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-fix {
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.img-fluid {
  width: 100%;
}

.nav {
  margin-left: 0;
  padding-left: 0;
}

.nav,
.nav a {
  display: block;
  list-style: none;
}

.nav a {
  padding: 0.3rem 0;
}

.main-nav-title {
  font-size: 1em;
  margin-bottom: 1.8rem;
  font-weight: 400;
  color: var(--gray-4);
}

.mb-0 {
  margin-bottom: 0em;
}
.mb-1 {
  margin-bottom: 0.2em;
}
.mb-2 {
  margin-bottom: 0.4em;
}
.mb-3 {
  margin-bottom: 0.8em !important;
}
.mb-4 {
  margin-bottom: 1em;
}
.mb-5 {
  margin-bottom: 1.8rem;
}
.mt-0 {
  margin-top: 0em;
}
.mt-1 {
  margin-top: 0.2em;
}
.mt-2 {
  margin-top: 0.4em;
}
.mt-3 {
  margin-top: 0.8em;
}
.mt-4 {
  margin-top: 1em;
}
.mt-5 {
  margin-top: 1.5rem;
}
.ml-1 {
  margin-left: 0.2em;
}
.ml-2 {
  margin-left: 0.4em;
}
.ml-3 {
  margin-left: 0.8em;
}
.ml-4 {
  margin-left: 1em;
}
.ml-5 {
  margin-left: 1.5rem;
}
.mr-1 {
  margin-right: 0.2em;
}
.mr-2 {
  margin-right: 0.4em;
}
.mr-3 {
  margin-right: 0.8em;
}
.mr-4 {
  margin-right: 1em;
}
.mr-5 {
  margin-right: 1.5rem;
}
.font-weight-regular {
  font-weight: 400;
}
.font-weight-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic !important;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/* Forms */
label {
  margin-bottom: 5px;
}

::placeholder {
  opacity: 0.6;
}

.form-control {
  border: 1px solid var(--gray-3);
  padding: 18px 18px 15px;
  max-width: 280px;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  appearance: none;
  box-sizing: border-box;
}

select.form-control {
  background-image: linear-gradient(45deg, transparent 50%, var(--gray-5) 50%),
    linear-gradient(135deg, var(--gray-5) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 21px) calc(1.5em + 2px), calc(100% - 14px) calc(1.5em + 2px),
    100% 0;
  background-size: 7px 7px, 7px 7px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.checkbox {
  background: var(--gray-3);
}

.checkbox:checked {
  background: var(--primary-400);
}

.form-control::placeholder {
  color: var(--text-black);
}

.combo-form {
  position: relative;
}

.combo-form > select.form-control {
  border: 1px solid var(--gray-3);
  position: absolute;
  height: 100%;
  padding: 0.5em;
  right: 0;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  appearance: none;
  box-sizing: border-box;
}
.form-group {
  display: flex;
  flex-direction: column;
}

.form-control-100 {
  max-width: 100% !important;
}

.form-group {
  margin-bottom: 2em;
}

.hide {
  display: none;
}

/* Buttons */
.btn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 20px;
  text-decoration: none;
}

.btn-sm {
  padding: 0.3rem 1rem;
}

.btn-primary {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.btn-primary:hover {
  border: 1px solid var(--primary-600);
  background-color: var(--primary-600);
  color: var(--white);
}
.btn-primary:active {
  border: 1px solid var(--primary-400);
  background-color: var(--primary-400);
}

.btn-light {
  border: 1px solid var(--white);
  background: var(--white);
  color: var(--gray-5);
  transition: all 0.3s ease-in-out;
}

.btn-light:hover {
  border: 1px solid var(--white);
  background: var(--gray-2);
  color: var(--primary);
}

/* Outline buttons */
.btn-outline-light {
  border: 1px solid var(--white);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.btn-outline-light:hover {
  border: 1px solid var(--white);
  background-color: var(--white);
  color: var(--gray-5);
}

.btn-outline-primary {
  border: 1px solid var(--primary);
  background-color: transparent;
  color: var(--gray-5);
  transition: all 0.3s ease-in-out;
}
.btn-outline-primary:hover {
  border: 1px solid var(--primary-600);
  background-color: rgba(0, 0, 0, 0.15);
}

.btn-outline-secondary {
  border: 1px solid var(--gray-3);
  background-color: transparent;
  color: var(--gray-5);
  transition: all 0.3s ease-in-out;
}
.btn-outline-secondary.active {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.btn-outline-secondary:hover {
  border: 1px solid var(--gray-4);
  background-color: rgba(0, 0, 0, 0.15);
}

.table-responsive table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid var(--gray-3);
}

.table-responsive th, .table-responsive td {
  /* text-align: left; */
  padding: 8px;
}

.table-striped tr:nth-child(even) {
  background-color: var(--gray-2);
}

.c-pointer {
  cursor: pointer !important;
}

.checkbox-accent {
  accent-color: var(--primary);
}